import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Observable } from 'rxjs';

//Services
import { ErrorhandlerService } from './errorhandler.service';

//Environment
import { environment } from '../../environments/environment';

//models
import { Entry } from '../models/entry';

@Injectable({
  providedIn: 'root'
})
export class EntryService {

  constructor(
    private http: HttpClient,
    private errorhandlerService: ErrorhandlerService) { }

    private apiUrl = environment.apiUrl;

    //Create a new User (Subscriber by default)
    submitEntry(entry: Entry): Observable<any> {
      let body = JSON.stringify(entry);
      console.log('Submitting...');
      return this.http.post<any[]>(this.apiUrl + 'entry', body)
        .pipe(
          map(response => response),
          catchError(this.errorhandlerService.handleError())
        );
    }



}
