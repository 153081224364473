import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule }   from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { Angulartics2Module } from 'angulartics2';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import { AppRoutingModule } from './app-routing.module';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';

//Components
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
//import { LandingComponent} from './components/landing/landing.component';
import { SweepstakesComponent } from './components/sweepstakes/sweepstakes.component';
import { SweepstakesdetailComponent } from './components/sweepstakesdetail/sweepstakesdetail.component';

//Containers
import { ContentcontainerComponent } from './containers/contentcontainer/contentcontainer.component';

//Services
import { CampaignService } from './services/campaign.service';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
	//LandingComponent,
    ContentcontainerComponent,
    SweepstakesComponent,
    SweepstakesdetailComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    AutocompleteLibModule,
    Angulartics2Module.forRoot(),
  ],
  providers: [CampaignService],
  bootstrap: [AppComponent]
})
export class AppModule { }
