import { Component, OnInit, Output, Input } from '@angular/core';

//Services
import { CampaignService } from '../../services/campaign.service';

@Component({
  selector: 'app-contentcontainer',
  templateUrl: './contentcontainer.component.html',
  styleUrls: ['./contentcontainer.component.scss']
})
export class ContentcontainerComponent implements OnInit {

  ipAddress$: string | null;

  @Input() showEndState: boolean;

  ngOnInit() {
    if(!this.showEndState) { this.showEndState = false; }
    this.campaignService.getIpAddress()
    .subscribe((data) => {
      //console.log(data);
      if(data && data.ip){
        this.ipAddress$ = data.ip;
      }
      else {
        this.ipAddress$ = 'N/A';
      }
    });
  }

  constructor(
    private campaignService: CampaignService
  ) { }

}
