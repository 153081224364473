import { Component, OnInit } from '@angular/core';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';

//Services
import { CampaignService } from './services/campaign.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  showEndState$: boolean | null;

  constructor(
    angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics,
    private campaignService: CampaignService) {
    angulartics2GoogleAnalytics.startTracking();
  }

  ngOnInit(){
    this.campaignService.checkCampaign()
    .subscribe((data) => {
      if (data.errorMessage) {
        data = JSON.parse(data.errorMessage);
      }
      if(data.status == 203) {
        this.showEndState$ = true;
      }
      else if(data.status == 400) {
        let url: string = 'https://kca.nick.tv/';
        this.campaignService.redirectToUrl(url);
      }
    });
  }


}
