import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';

//Services
import { ErrorhandlerService } from './errorhandler.service';

//Environment
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CampaignService {

  constructor(
    private http: HttpClient,
    private errorhandlerService: ErrorhandlerService) { }

    private apiUrl = environment.apiUrl;

    //Check the campaign start and end date
    checkCampaign(): Observable<any> {

      return this.http.get<any[]>(this.apiUrl + 'campaignv2')
        .pipe(
          catchError(this.errorhandlerService.handleError())
        );
    }

    redirectToUrl(url: string) {
      window.location.href= url;
    }

    getIpAddress(): Observable<any> {
      return this.http.get<any[]>('https://jsonip.com')
      .pipe(
        catchError(this.errorhandlerService.handleError())
      );
    }

}
