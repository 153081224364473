import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CableProviderListService {

  constructor() {
  }

  getList() {
    return [
	"AT&T U-VERSE",
"COMCAST XFINITY",
"COX COMMUNICATIONS",
"DISH",
"DIRECTV",
"DIRECTV STREAM",
"FRONTIER COMMUNICATIONS",
"FUBOTV",
"MEDIACOM",
"NO CABLE PROVIDER",
"OPTIMUM BY ALTICE",
"PHILO",
"SLING TV",
"SPARKLIGHT",
"SPECTRUM",
"SUDDENLINK",
"VERIZON FIOS",
"YOUTUBE TV",
"A.S.C. / ADVANCED SATELLITE SYSTEMS",
"ACCESS CABLE",
"ACCESS MONTANA",
"ACME COMMUNICATIONS INC",
"ADAMS TELCOM",
"ALBANY MUTUAL TELEPHONE",
"ALENCO COMMUNICATIONS INC.",
"ALLIANCE COMMUNICATIONS",
"ALLIANCE COMMUNICATIONS",
"ALLO COMMUNICATIONS",
"ALPINE COMMUNICATIONS",
"ALSEA RIVER CABLE",
"ALTATEC / ALTA MUNICIPAL UTILITIES",
"AMERICABLE",
"AMERICAN CABLE TV, INC.",
"AMHERST COMMUNICATIONS",
"AMU / ALGONE MUNICIPAL UTILITIES",
"ARMSTRONG",
"ARTHUR MUTUAL TELEPHONE",
"ASTOUND BROADBAND",
"ATC COMMUNICATIONS / ATKINS CABLEVISION",
"ATC JET / ATC COMMUNICATIONS",
"ATVCI /ALLENS TV CABLE SERVICE",
"ATWOOD CABLE",
"AURORA BY HICKORY TELEPHONE COMPANY",
"AYERSVILLE COMMUNICATIONS",
"BAGLEY PUBLIC UTILITIES",
"BALDWIN CABLEVISION",
"BALDWIN LIGHTSTREAM",
"BARBOURVILLE UTILITIES",
"BARDSTOWN CABLE TV & INTERNET",
"BASCOM COMMUNICATIONS",
"BAY COUNTRY COMMUNICATIONS",
"BAYOU CABLE INC",
"BCT / BEAVER CREEK COOPERATIVE TELEPHONE",
"BEAM",
"BEAMSPEED INTERNET SERVICE",
"BEAVER VALLEY CABLE",
"BEE LINE CABLE",
"BEEHIVE BROADBAND",
"BELHAVEN CABLE TV INC",
"BELLEVUE TELEVISION",
"BELZONI CABLE, LLC",
"BEN LOMAND CONNECT",
"BEND BROADBAND",
"BENTON COMMUNICATIONS",
"BEVCOMM",
"BLOOMINGDALE COMMUNICATIONS",
"BLUE DEVIL CABLE",
"BLUE RIDGE COMMUNICATIONS",
"BLUE STREAM FIBER",
"BLUE VALLEY TECHNOLOGIES",
"BLUESKY COMMUNICATIONS",
"BLUESTREAM",
"BMTC / BERESFORD MUNICIPAL TELEPHONE COMPANY",
"BMU / BRYAN MUNICIPAL UTILITIES",
"BOLT",
"BRANDENBURG COMMUNICATIONS",
"BREEZELINE",
"BRIGHTRIDGE",
"BROCKWAY TELEVISION",
"BRT / BENTON RIDGE TELEPHONE COMPANY",
"BTC / BALLARD RURAL TELEPHONE COOPERATIVE CORP",
"BTC COMMUNICATIONS",
"BTEL",
"BTES / BRISTOL TENNESSEE ESSENTIAL SERVICES",
"BUCKEYE BROADBAND",
"BUFFALO CABLE TV CO",
"BULLOCH SOLUTIONS",
"BW TELCOM",
"CABLE COMMUNICATIONS OF WILLSBORO",
"CABLE PLUS CABLE",
"CABLE SERVICES COMPANY, INC.",
"CABLEAMERICA",
"CALTEL CONNECTIONS",
"CAMERON COMMUNICATIONS",
"CAPROCK TELEPHONE",
"CARNEGIE TELEPHONE CO.",
"CAROLINA MOUNTAIN CABLEVISION, INC",
"CARR COMMUNICATIONS",
"CAS CABLE",
"CASCADE COMMUNICATIONS",
"CASEY MUTUAL TELEPHONE COMPANY",
"CASSCOM",
"CASTLE CABLE TV, INC",
"CATC / CENTRAL ARKANSAS TELEPHONE COOPERATIVE",
"CC COMMUNICATIONS",
"CCS / COMMUNICATION CONSTRUCTION SERVICES I",
"CELECT COMMUNICATIONS",
"CELINA CABLE COMMUNICATIONS",
"CENTRACOM",
"CHAPARRAL CABLEVISION",
"CHARITON VALLEY",
"CHESNEE COMMUNICATIONS",
"CINCINNATI BELL",
"CIRRINITY / WITTENBERG TELEPHONE COMPANY",
"CITIZENS FIBER",
"CITIZENS MUTUAL",
"CITIZENS TELEPHONE",
"CITIZENS TELEPHONE CORPORATION",
"CITY OF FORT COLLINS ELECTRIC UTILITY ENTERPRISE",
"CITY OF MONROE UTILITIES",
"CITY TV CABLE SERVICE",
"CL TEL / CLEAR LAKE INDEPENDENT TELEPHONE CO",
"CLARENCE TELEPHONE COMPANY",
"CLEAR CREEK COMMUNICATIONS",
"CLICK! CABLE TV",
"CML TELEPHONE COOPERATIVE ASSOCIATION",
"CNS NEXT",
"CO-MO CONNECT",
"COAST COMMUNICATIONS",
"COLTONTEL",
"COMM1",
"COMMUNITY ANTENNA SYSTEM INC",
"COMMUNITY CABLE AND BROADBAND, INC.",
"COMMZOOM",
"COMPAS",
"COMPORIUM",
"CONCEPT COMMUNICATION CORP",
"CONSOLIDATED COMMUNICATIONS",
"CONSOLIDATED TELCOM",
"CONWAY CORP",
"COOPERATIVE TELEPHONE EXCHANGE",
"CORN BELT TELEPHONE",
"COUNTRY CABLEVISION / CCVN",
"CPWS / COLUMBIA POWER & WATER SYSTEMS",
"CRAW-KAN TELEPHONE COOPERATIVE INC",
"CRMU / COON RAPIDS MUNICIPAL UTILITIES",
"CROSSLAKE COMMUNICATIONS",
"CSPIRE",
"CTC TECHNOLOGY",
"CTS BROADBAND",
"CUNNINGHAM COMMUNICATIONS INC",
"CVTC / COON VALLEY TELECOMMUNICATIONS",
"CWA CABLE TV",
"D & P COMMUNICATIONS",
"DAKOTA CENTRAL",
"DARIEN TELEPHONE COMPANY",
"DCI CABLE",
"DEMOPOLIS CATV",
"DESDEN CABLE INC",
"DIAMONDNET",
"DIGIMAX FIBER",
"DIGITAL CONNECTIONS, INC.",
"DIODE COMMUNICATIONS",
"DIRECTLINK",
"DIVERSE COMMUNICATIONS INC",
"DIXIE CABLE TV CO INC",
"DIXIE CABLEVISION INC",
"DOYLESTOWN COMMUNICATIONS, INC.",
"DTC / DTC COMMUNICATIONS",
"DTC CABLE INC",
"DUMONT TELEPHONE",
"DUNCAN CABLE TV",
"DUNKERTON COMMUNICATIONS",
"DUO BROADBAND",
"E.L. AUTOMATION, INC.",
"EASTERN CABLE CORPORATION",
"EASTON UTILITIES",
"EATEL",
"ECTC / EMILY COOP TELEPHONE CATV DIVISION",
"ELBERTON UTILITIES",
"ELEVATE",
"ELLSWORTH COOPERATIVE TELEPHONE ASSOCIATION",
"EMERY TELCOM",
"ENDEAVOR COMMUNICATIONS",
"EPB / GLASGOW ELECTRIC PLANT BOARD",
"EPB CHATTANOOGA",
"ETC",
"ETEX",
"FARMERS CO-OPERATIVE TELEPHONE CO",
"FARMERS MUTUAL COOP TELEPHONE CO",
"FARMERS MUTUAL TELEPHONE",
"FBN INDIANA, INC.",
"FIBERNET MONTICELLO",
"FLETCHER SMITH & ASSOCIATES",
"FLINT RIVER COMMUNICATIONS",
"FOCUS BROADBAND",
"FOOTHILLS COMMUNICATIONS",
"FORSYTH CABLENET, LLC",
"FORT JENNINGS TELEPHONE CO.",
"FORT MOJAVE TELECOMMUNICATIONS, INC",
"FORT RANDALL TELEPHONE COMPANY",
"FOSSTON CABLE TV",
"FPU TELECOM",
"FRANK HOWARD TV CABLE INC",
"FRANKFORT PLANT BOARD",
"FRANKLIN TELEPHONE COMPANY",
"FTC / FARMERS TELEPHONE COMPANY",
"G TEL",
"GAINESBORO CATV INC",
"GARDONVILLE COOPERATIVE TELEPHONE ASSOCIATION",
"GCI / GCI CABLE",
"GENUINE TELECOM",
"GEUS / GREENVILLE ELECTRIC UTILITY SYSTEM",
"GIANT COMMUNICATIONS, INC.",
"GIBSON ELECTRIC MEMBERSHIP CORPORATION",
"GOLDEN RAIN FOUNDATION OF LAGUNA WOODS",
"GOLDEN VALLEY CABLE",
"GOLDEN WEST",
"GOLDFIELD TELECOM",
"GOOGLE FIBER, INC.",
"GRAND MOUND COOPERATIVE TEL",
"GRANTSBURG TELCOM",
"GREAT PLAINS COMMUNICATIONS",
"GREAT WAVE COMMUNICATIONS",
"GREENLIGHT COMMUNITY BROADBAND",
"GRIDCOM",
"GRISWALD COMMUNICATIONS",
"GUAM CABLEVISION",
"GVTC COMMUNICATIONS",
"H & B COMMUNICATIONS",
"HAEFELE CONNECT",
"HANCOCK TELEPHONE",
"HARLAN COMMUNITY TELEVISION INC",
"HARRIS BROADBAND LP",
"HARRISONVILLE TELEPHONE COMPANY",
"HAWKEYE TELEPHONE COMPANY",
"HBC / HIAWATHA BROADBAND COMMUNICATIONS INC",
"HEART OF IOWA COMMUNICATIONS COOPERATIVE",
"HERSHEY COOPERATIVE TELEPHONE COMPANY",
"HIGHLAND COMMUNICATIONS",
"HILLSBORO TELEPHONE",
"HMU / HARLAN MUNICIPAL UTILITIES",
"HOLSTON ELECTRIC COOPERATIVE, INC.",
"HOME TELECOM",
"HOMETEL",
"HOOD CANAL COMMUNICATIONS",
"HORIZON",
"HORIZON CABLE TV",
"HOTWIRE COMMUNICATIONS, LTD",
"HTC / HALSTAD TELEPHONE COMPANY",
"HTC / HART CABLE",
"HTC / HORRY TELEPHONE COOPERATIVE",
"HUNTEL",
"HUTCHINSON TELECOMMUNICATIONS INC",
"HUTCHINSON TELEPHONE COMPANY",
"I3 BROADBAND",
"INDCO",
"INDEPENDECE LIGHT & POWER TELECOMMUNICATIONS",
"INDUSTRY I-NET, INC.",
"INFINITYLINK COMMUNICATIONS",
"INFOSTRUCTURE",
"INLAND NETWORKS",
"INTER-COUNTY CABLE CO.",
"IRON RIVER CABLE",
"IRVINE COMMUNITY TELEVISION",
"ITC / INTERSTATE TELECOMMUNICATIONS COOP",
"J.B. CABLE TV COMPANY",
"JACKSON ENERGY AUTHORITY",
"JCC / JEFFERSON COUNTY CABLE TV",
"JEFFERSON TELECOM",
"JVT / JAMES VALLEY TELECOMMUNICATIONS",
"KALIDA TELEPHONE COMPANY INC",
"KAPTEL",
"KEYSTONE COMMUNICATIONS",
"KINETIC BY WINDSTREAM",
"KMTELECOM",
"KPU TELECOMMUNICATIONS",
"KRAUS",
"LA HARPE TELEPHONE COMPANY",
"LA HARPE TELEPHONE COMPANY, INC.",
"LA MOTTE / ANDREW TELEPHONE COMPANY",
"LAFAYETTE UTILITIES SYSTEM",
"LAKE REGION TECHNOLOGY & COMMUNICATIONS, LLC",
"LAKELAND COMMUNICATIONS",
"LEHIGH VALLEY COOPERATIVE TELEPHONE ASSOCIATION",
"LENNON TELEPHONE COMPANY / TVC CABLE & INTERNET SOLUTIONS",
"LENOX MUNICIPAL UTILITIES",
"LHTC BROADBAND",
"LIGHTSTREAM",
"LIGTEL COMMUNICATIONS",
"LIMESTONE AND BRACKEN CABLE COMPANY",
"LINCTEL",
"LISCO",
"LMPC / LAURENS MUNICIPAL POWER & COMMUNICATIONS",
"LNE COMMUNICATIONS",
"LOCALTEL",
"LPC CONNECT",
"LTC / LAVALLE TELEPHONE COOPERATIVE",
"LUMOS NETWORKS",
"LVT CORP",
"LYCOM COMMUNICATIONS INC",
"M2X COMMUNICATIONS",
"MADISON COMMUNICATIONS",
"MADISON COUNTY TELEPHONE CO.",
"MARQUETTE ADAMS TELEPHONE COOPERATIVE",
"MARTELLE COMMUNICATIONS CO-OP",
"MBC OKINAWA",
"MCCLANAHAN CABLE INC",
"MCTV / MASSILLON CABLE TV INC",
"MECHANICSVILLE TELEPHONE COMPANY",
"METC / MARNE ELK HORN",
"METRO COMMUNICATIONS COMPANY, INC.",
"METRONET",
"MHTC / MH TELCOM, LLC",
"MICHIGAN BROADBAND SERVICES",
"MID-HUDSON CABLE",
"MIDCENTURY COMMUNICATIONS",
"MIDSTATE COMMUNICATIONS INC",
"MIDTEL",
"MILFORD COMMUNICATIONS, LLC",
"MILLHEIM TV TRANSMISSION CO",
"MINBURN COMMUNICATIONS",
"MINET",
"MINFORD TELEPHONE COMPANY",
"MITCHELL TELECOM",
"MLGC",
"MONCRE",
"MORRIS BROADBAND LLC",
"MORRISTOWN UTILITY SYSTEMS",
"MOUNTAIN RURAL TELEPHONE COOPERATIVE CORPORATION",
"MOUNTAIN TELEPHONE",
"MOUNTAIN ZONE TV SYSTEMS",
"MTC / MUTUAL TELECOMMUNICATIONS",
"MTC CABLE / MARGARETVILLE TELEPHONE COMPANY",
"MTCC / MANTI TELEPHONE COMPANY",
"MULBERRY TELECOMMUNICATIONS",
"MURRAY ELECTRIC SYSTEM",
"NCC / NORTHWEST COMMUNICATIONS COOPERATIVE",
"NCTC / NEBRASKA CENTRAL TELECOM",
"NCTC / NORTH CENTRAL COMMUNICATIONS",
"NDTC / NORTH DAKOTA TELEPHONE COMPANY",
"NEIT / NORTHEAST IOWA TELEPHONE COMPANY",
"NELSON CABLE",
"NELSONVILLE TV",
"NEP",
"NEWPORT UTILITIES",
"NEX-TECH INC",
"NEXTGEN BROADBAND",
"NHTC / NEW HOPE TELEPHONE COOPERATIVE",
"NICP / NORTHERN IOWA COMMUNICATIONS PARTNERS",
"NINESTAR CONNECT",
"NITTANY MEDIA INC",
"NK TELCO, INC.",
"NNTC / NORTHEAST NEBRASKA TELEPHONE COMPANY",
"NORTH ALABAMA ELECTRIC COOPERATIVE",
"NORTHPENN",
"NORTHSTATE",
"NORTHWEST COMUNICATIONS",
"NORVADO",
"NORVADO, INC.",
"NORWOOD LIGHT BROADBAND",
"NOVA1NET",
"NSIGHT TELSERVICES",
"NTEC",
"NUVERA",
"NVC / NORTHERN VALLEY COMMUNICATIONS",
"OBERLIN CABLE CO-OP",
"OGDEN TELEPHONE",
"OMNITEL COMMUNICATIONS",
"ONEIDA TELEPHONE EXCHANGE",
"ONESOURCE COMMUNICATIONS",
"OPTICALTEL",
"ORION BROADBAND",
"OTEC COMMUNICATION / OTTOVILLE MUTUAL TELEPHONE",
"OZARKSGO, LLC",
"PALMER MUTUAL TELEPHONE COMPANY",
"PALO COOPERATIVE TELEPHONE ASSOCIATION",
"PARAGOULD LIGHT WATER CABLE",
"PARK REGION TELEPHONE CO.",
"PARTNER COMMUNICATIONS INC.",
"PATRIOT CABLE",
"PAUL BUNYAN COMMUNICATIONS",
"PEMBROKE ADVANCED COMMUNICATIONS",
"PES / PULASKI ELECTRIC SYSTEM",
"PHOENIX CABLE, INC.",
"PHONOSCOPE",
"PICKWICK CABLEVISION",
"PIEDMONT COMMUNICATIONS",
"PINE BELT COMMUNICATIONS, INC.",
"PINELAND",
"PINPOINT",
"PIONEER",
"PIONEER BROADBAND",
"POINT BROADBAND, LLC",
"POLAR COMMUNICATIONS",
"PORTAL TELEVISION",
"PREMIER COMMUNICATIONS",
"PREMIER COMMUNICATIONS INC",
"PROCOM COMMUNICATIONS",
"PROVINCIAL CABLE",
"PRTC / PEOPLE'S RURAL TELEPHONE COOPERATIVE",
"PRTC / PEOPLES RURAL TELEPHONE COOPERATIVE CORPORATION",
"PSC / PERRY-SPENCER COMMUNICATIONS INC",
"PVT / PEÑASCO VALLEY TELEPHONE",
"QCOL, INC.",
"QUALITY CABLEVISION",
"QXC COMMUNICATIONS",
"RADCLIFFE TELEPHONE",
"RAINBOW / CHRISTIAN ENTERPRISES",
"RAINIER CONNECT",
"RALLS TECHNOLOGIES, LLC",
"RANDOLPH COMMUNICATIONS",
"RC TECHNOLOGIES",
"RCTA / ROCKWELL COOPERATIVE TELEPHONE ASSOCIATION",
"REACH BROADBAND",
"RED RIVER CABLE TV CO INC",
"RED RIVER COMMUNICATIONS",
"REDERSBURG SMULLTON CABLE CO",
"REEDSBURG UTILITY COMMISSION",
"RELIANCE CONNECTS",
"REYNOLDS CABLE, INC.",
"RGTC / RICHLAND-GRANT TELEPHONE COOP",
"RITTER COMMUNICATIONS",
"RIVER VALLEY TELECOMMUNICATIONS COOP.",
"RIVIERA UTILITIES",
"ROCK PORT TELEPHONE",
"RSFIBER",
"RTC / RESERVATION TELEPHONE COOPERATIVE",
"RTC FIBER COMMUNICATIONS",
"RTEC COMMUNICATIONS / RIDGEVILLE TELEPHONE COMPANY",
"RTI RURAL TELECOM",
"S & K TV SYSTEMS INC",
"S&T",
"SAC COUNTY MUTUAL TELEPHONE COMPANY",
"SALEM CABLE VISION",
"SALISH NETWORKS",
"SAN BRUNO CITYNET SERVICES",
"SAN JUAN CABLE & INTERNET",
"SANDHILL TELEPHONE COOPERATIVE, INC.",
"SANTA ROSA COMMUNICATIONS LTD",
"SANTEL COMMUNICATIONS",
"SCC / SUBURBAN CABLE CO",
"SCHURZ COMMUNICATIONS, INC.",
"SCI BROADBAND",
"SCOTTSBORO ELECTRIC POWER BOARD",
"SCRANTON TELEPHONE COMPANY",
"SCRTC / SOUTH CENTRAL TELCOM",
"SCTA / SPRINGVILLE CO-OP TELEPHONE ASSN",
"SCTC / SCOTT TELECOM AND ELECTRONICS INC",
"SECURE VISION, INC.",
"SELCO RESIDENTIAL",
"SERVICE ELECTRIC CABLE TV & COMMUNICATIONS",
"SERVICE ELECTRIC CABLEVISION INC",
"SFCN / SPANISH FORK COMMUNBITY NETWORK",
"SHARON TELEPHONE COMPANY",
"SHENTEL",
"SIERRA NEVADA COMMUNICATIONS",
"SISTER LAKES CABLE",
"SJOBERGS INC",
"SLIC BROADBAND INTERNET",
"SMTA / SHERWOOD MUTUAL TELEPHONE ASSOCIATION",
"SMTA COMMUNICATION AND TECHNOLOGY COOP",
"SMU / SPENCER MUNICIPAL UTILITIES",
"SOLARUS",
"SOMERFIELD CABLE TV",
"SOUTH PLAINS CABLE",
"SOUTH SLOPE",
"SPRING CITY CABLE",
"SPRINGPORT TELEPHONE CO. / SPRINGCOM",
"SRT",
"ST. JOHN CABLE CO.",
"STANTON TELECOM INC.",
"STAR COMMUNICATIONS",
"STC / SCHALLER TELEPHONE COMPANY",
"STC / SYCAMORE TELEPHONE COMPANY",
"STOWE CABLE SYSTEMS",
"STRATFORD MUTUAL TELEPHONE",
"STRATUSIQ",
"SUMMIT BROADBAND, INC.",
"SUMNER COMMUNICATIONS",
"SUNRISE COMMUNICATIONS LLC",
"SUPERIOR CABLE & DATA",
"SVEC / SEQUACHEE VALLEY ELECTRIC COOPERATIVE",
"SWAT / SOUTHWEST ARKANSAS TELEPHONE COOP, INC.",
"SWAYZEE COMMUNICATIONS CORP",
"SWYFT CONNECT",
"TCC / TRI-COUNTY COMMUNICATIONS",
"TCT / TRI COUNTY TELEPHONE",
"TDS TELECOM",
"TEL-STAR COMMUNICATIONS, INC.",
"THE CABLE TV COMPANY",
"THREE RIVER",
"TONGUE RIVER COMMUNICATIONS, INC.",
"TRANS-VIDEO INC",
"TRI-COUNTY COMMUNICATIONS COOPERATIVE",
"TRIOTEL COMMUNICATIONS, INC.",
"TROY CABLE",
"TSC / TELEPHONE SERVICE COMPANY",
"TTC / TEMPLETON TELECOM",
"TULLAHOMA UTILITIES AUTHORITY",
"TV ASSOCIATION OF REPUBLIC",
"UNION TELEPHONE COMPANY",
"UNITED COMMUNICATIONS",
"UNITED SERVICES, INC.",
"USA COMMUNICATIONS",
"UTILITY SERVICE OF HAWARDEN",
"VALLEY TELECOMMUNICATIONS",
"VALPARAISO BROADBAND",
"VALUNET FIBER",
"VAST BROADBAND",
"VENTURE COMMUNICATIONS",
"VENUS TELEPHONE CORPORATION",
"VERACITY NETWORKS",
"VERNON COMMUNICATIONS CO-OP",
"VEXUS",
"VIKING BROADBAND INC",
"VIOLA HOME TELEPHONE COMPANY",
"VISION COMMUNICATIONS",
"VNET",
"VOGTMANN ENGINEERING INC",
"VOLCANO COMMUNICATIONS GROUP",
"VS ENTERPRISES LTD",
"VTEL",
"WABASH",
"WABASH COMMUNICATIONS",
"WADSWORTH CABLE & TV",
"WALDRON COMMUNICATION COMPANY",
"WATSON ONLINE",
"WAVE WIRELESS, LLC",
"WBI / WESTPHALIA BROADBAND INC",
"WCTA / WINNEBAGO COOPERATIVE TELECOM ASSOC.",
"WCVT / WAITSVILLE AND CHAMPLAIN VALLEY TELECOM",
"WEBSTER-CALHOUN COOP TELEPHONE ASSOC",
"WEHCO MEDIA, INC.",
"WELLMAN COOPERATIVE TELEPHONE ASSOCIATION",
"WEST CENTRAL TELEPHONE ASSOCIATION",
"WEST RIVER COOPERATIVE TELEPHONE COMPANY",
"WEST TEXAS RURAL TELEPHONE COOPERATIVE",
"WESTEL SYSTEMS",
"WESTERN IOWA NETWORKS",
"WESTEX CONNECT",
"WESTFIELD COMMUNITY ANTENNA",
"WIATEL",
"WICONNECT WIRELESS, LLC",
"WILCOP CABLE TV",
"WILKES COMMUNICATIONS, INC.",
"WILLIAMSTOWN CABLE",
"WILSON COMMUNICATIONS",
"WINDOMNET",
"WIRE TELE-VIEW",
"WK&T",
"WOW! / WIDEOPENWEST NETWORKS",
"WTC COMMUNICATIONS INC",
"WYANDOTTE CABLE",
"WYOMING MUTUAL TELEPHONE COMPANY, INC.",
"XIT COMMUNICATIONS",
"YELCOT COMMUNICATIONS",
"YOUNGSVILE TELEVISION CORPERATIONS",
"ZIPLY FIBER",



];
  }
}
