import { Component, Input, OnInit } from '@angular/core';
import { Angulartics2 } from 'angulartics2';

//Models
import { Entry } from '../../models/entry';

//Services
import {EntryService} from '../../services/entry.service';
import {CableProviderListService} from '../../services/cable-provider-list.service';

@Component({
  selector: 'app-sweepstakes',
  templateUrl: './sweepstakes.component.html',
  styleUrls: ['./sweepstakes.component.scss']
})
export class SweepstakesComponent implements OnInit {

  @Input() ipAddress: string | null;

  keyword = 'name';

  providerList: any = [];

  isLoading: boolean = false;

  model: any = {};

  showErrorMsg: boolean = false;

  errorMsg: string ='';

  entrySuccess: boolean = false;

  months: { value: string, name: string }[] = [
      { "value": "01", "name": "Jan" },
      { "value": "02", "name": "Feb" },
      { "value": "03", "name": "Mar" },
      { "value": "04", "name": "Apr" },
      { "value": "05", "name": "May" },
      { "value": "06", "name": "Jun" },
      { "value": "07", "name": "Jul" },
      { "value": "08", "name": "Aug" },
      { "value": "09", "name": "Sep" },
      { "value": "10", "name": "Oct" },
      { "value": "11", "name": "Nov" },
      { "value": "12", "name": "Dec" }
  ];

  days: string[] = [
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
    '24',
    '25',
    '26',
    '27',
    '28',
    '29',
    '30',
    '31'
  ]

  years: string[] = [
    '2021', '2020', '2019', '2018', '2017',
	'2016', '2015', '2014', '2013', '2012',
	'2011', '2010', '2009', '2008', '2007',
	'2006', '2005', '2004', '2003', '2002',
	'2001', '2000', 
	'1999', '1998', '1997', '1996', '1995',
    '1994', '1993', '1992', '1991', '1990',
    '1989', '1988', '1987', '1986', '1985',
    '1984', '1983', '1982', '1981', '1980',
    '1979', '1978', '1977', '1976', '1975',
    '1974', '1973', '1972', '1971', '1970', 
	'1969', '1968', '1967', '1966',
    '1965', '1964', '1963', '1962', '1961',
    '1960', '1959', '1958', '1957', '1956',
    '1955', '1954', '1953', '1952', '1951',
    '1950', '1949', '1948', '1947', '1946',
    '1945', '1944', '1943', '1942', '1941',
    '1940', '1939', '1938', '1937', '1936',
    '1935', '1934', '1933', '1932', '1931',
    '1930', '1929', '1928', '1927', '1926',
    '1925', '1924', '1923', '1922', '1921',
    '1920', '1919', '1918', '1917', '1916',
    '1915', '1914', '1913', '1912', '1911',
    '1910', '1909', '1908', '1907', '1906',
    '1905', '1904', '1903', '1902', '1901',
    '1900'
  ]

  ngOnInit() {
    this.providerList = this.cableProviderListService.getList();
    console.log(this.providerList);
  }
  constructor(
    private entryService: EntryService,
    private cableProviderListService: CableProviderListService,
    private angulartics2: Angulartics2) { }

  cableProviderValid(selectedProvider) {
    return this.providerList.indexOf(selectedProvider) !== -1;
  }

  handleSubmit(entry: Entry, isValid: boolean){
    if(isValid && this.cableProviderValid(entry.cableProvider)){
      this.angulartics2.eventTrack.next({
        action: 'Submitted Entry Form',
        properties: { category: 'Form-Submission' },
      });
      this.showErrorMsg = false;
      entry.ipAddress = this.ipAddress;
      //console.log(entry);
      this.isLoading = true;
      this.entryService.submitEntry(entry)
      .subscribe((data) => {
        //console.log(data);
        if(data.errorMessage){
          data = JSON.parse(data.errorMessage);
          if(data.statusCode == 200){
            this.entrySuccess = true;
            this.isLoading = false;
          }
        }
        else {
          this.showErrorMsg = true;
          this.errorMsg = data.errorMsg;
          this.isLoading = false;
        }
      });
    }
    else {
      this.showErrorMsg = true;
      this.errorMsg = 'All fields are required!';
    }
  }
}
